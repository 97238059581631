import { useCallback, useEffect, useMemo, useState } from "react";

const formatTime = (time: number) => time.toString().padStart(2, "0");

export function useCountDown(date: Date) {
  const calculateTimeLeft = useCallback(
    () => () => {
      const targetTime = date.getTime();
      const currentTime = new Date().getTime();
      const difference = targetTime - currentTime;

      if (difference > 0) {
        return {
          days: formatTime(Math.floor(difference / (1000 * 60 * 60 * 24))),
          hours: formatTime(Math.floor((difference / (1000 * 60 * 60)) % 24)),
          minutes: formatTime(Math.floor((difference / 1000 / 60) % 60)),
          seconds: formatTime(Math.floor((difference / 1000) % 60)),
        };
      }

      return undefined;
    },
    [date],
  );

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return useMemo(() => timeLeft, [timeLeft]);
}
